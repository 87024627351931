<template>
  <div class="wrap">
    <div class="banner">
      <img v-if="clientWidth > 750" :src="bannerImg" alt="">
      <img v-else :src="bannerImg_m" alt="">
    </div>
    <div class="moduleBox">
      <div class="moduleBox-title1">
        “融汇数字创新，释放工业动能” 2023 剑维软件行业研讨会已经在宁波、西安、沈阳圆满落幕。研讨会荟聚水务、电子半导体、基础设施、钢铁、冶金、智慧城轨、石油和天然气等行业行家，共同前瞻工业行业未来发展趋势，解读剑维软件产品功能、创新案例、成功经验，加速释放行业动能，在数字化和低碳化双转型中破局开拓，在工业经济互联的世界中蓬勃发展。
      </div>
      <div class="moduleBox-title2">
        点击下方，获取精彩活动回顾 >>
      </div>
      <div class="moduleBox-listBox">
        <div class="moduleBox-list" v-for="(item, index) in activityList" :key="index" @click="goNext(item)">
          <div class="moduleBox-list-imgBox">
            <img :src="item.imgUrl1" class="moduleBox-list-img" alt="">
            <img :src="pauseIcon" class="moduleBox-list-imgPause" alt="">
          </div>
          <div class="moduleBox-list-contentBox">
            <div class="moduleBox-list-title1">{{item.extend1}}</div>
            <div class="moduleBox-list-timeBox">
              <div class="moduleBox-list-name">嘉宾：</div>
              <div class="moduleBox-list-text">{{item.extend2}}</div>
            </div>
            <div class="moduleBox-list-textBox">
              <div class="moduleBox-list-name">公司：</div>
              <div class="moduleBox-list-text">{{item.extend3}}</div>
            </div>
          </div>
          <div class="moduleBox-list-more">查看详细信息 >></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GetWxShares } from "@/utils/common";
import { GetTreeSelect } from "@/api/Home.js"
import { ApiToken } from '@/utils/ApiToken'

export default {
  data(){
    return{
      bannerImg: require('@/assets/PC/activity/workShopReview_banner.png'),
      bannerImg_m: require('@/assets/PC/activity/workShopReview_banner_m.png'),
      pauseIcon: require('@/assets/PC/newpage/icon_pause.png'),
      activityList: []
    }
  },
  mounted() {
    let data = {
      pid: ApiToken.workShopId,
      id: ''
    }
    this.GetTreeSelect(data)
    
    let obj = {
      title: '融汇数字创新，释放工业动能',
      desc: ''
    }
    GetWxShares(obj)
  },
  methods: {
    GetTreeSelect(data){
      GetTreeSelect(data).then(res =>{
        if(res.status == 1){
          this.activityList = res.data
        }else{
          this.$toast(res.message);
        }
        
      })
    },
    goNext(item) {
      window.open(item.extend4)
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  },
};
</script>
<style lang="scss" scoped>
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.wrap{
  width: 100%;
  overflow: hidden;
  font-size: 30px;
}
.banner{
  width: 100%;
  height: auto;
  display: block;
  img{
    width: 100%;
    height: auto;
    display: block;
  }
}
.moduleBox{
  width: 1200px;
  margin: 0 auto 70px;
}
.moduleBox-title1{
  text-indent: 28px; font-size: 16px; line-height: 32px;padding:62px 0 0; 
}
.moduleBox-title2{
  display: inline-block; font-size: 16px; line-height: 32px;padding:0 0 0; color: #6B18A8; 
}


.moduleBox-listBox{
  margin: 41px 0 75px;
  display: flex;
  flex-wrap: wrap;
  .moduleBox-list:nth-child(4n){
    margin-right: 0;
  }
  .moduleBox-list{
    width: calc((100% - 14px) / 4);
    max-width: 288px;
    margin: 0 14px 17px 0;
    border-radius: 6px;
    background: #f2f2f2;
    padding-bottom: 30px;
    position: relative;
    cursor: pointer;
    .moduleBox-list-imgBox{
      width: 288px;
      height: 163px;
      background: #000;
      position: relative;
      cursor: pointer;
      border-radius: 6px 6px 0 0;
      .moduleBox-list-img{
        width: 100%;
        object-fit: fill;
        border-radius: 6px 6px 0 0;
      }
      .moduleBox-list-imgPause{
        width: 55px;
        height: 56px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .moduleBox-list-contentBox{
      width: 100%;
      padding: 25px 17px 23px;
      border-radius: 0 0 6px 6px;
      background: #f2f2f2;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      position: relative;
      .moduleBox-list-title1{
        height: 78px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        line-height: 26px;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .moduleBox-list-timeBox, .moduleBox-list-textBox{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 6px;
        img{
          width: 16px;
          height: 16px;
        }
        .moduleBox-list-name{
          font-size: 16px;
          color: #000;
          line-height: 22px;
          font-weight: bold;
        }
        .moduleBox-list-text{
          font-size: 16px;
          color: #000;
          line-height: 22px;
          flex: 1;
        }
      }
      .moduleBox-list-textBox{
        align-items: flex-start;
        img{
          width: 15px;
          height: 17px;
        }
      }
      
    }
    .moduleBox-list-more{
      position: absolute;
      left: 17px;
      bottom: 28px;
      color: #6B18A8;
      font-size: 16px;
      cursor: pointer;
    }
  }
}


@media (max-width: 750px) {
  .banner{
    img{
      width: vw(750);
    }
  }
  .moduleBox{
    width: 100%;
    padding: 0 vw(40);
    margin-bottom: vw(70);
  }
  .moduleBox-title1{font-size: vw(24); line-height: vw(36);padding: vw(55) 0 0;}
  .moduleBox-title2{font-size: vw(24); line-height: vw(36);padding: vw(10) 0 0;}

  .moduleBox-listBox{
    margin: vw(60) 0 vw(73);
    .moduleBox-list:nth-child(2n){
      margin-right: 0;
    }
    .moduleBox-list{
      // width: calc((100% - 14px) / 2);
      width: vw(327);
      max-width: vw(327);
      margin: 0 vw(12) vw(15) 0;
      border-radius: 0 0 vw(6) vw(6);
      .moduleBox-list-imgBox{
        width: vw(327);
        height: vw(184);
        .moduleBox-list-img{
          width: 100%;
          border-radius: vw(6) vw(6) 0 0;
          
        }
        .moduleBox-list-imgPause{
          width: vw(63);
          height: vw(63);
        }
      }
      .moduleBox-list-contentBox{
        padding: vw(21) vw(20) vw(9);
        border-radius: 0 0 vw(6) vw(6);
        .moduleBox-list-title1{
          height: vw(148);
          font-size: vw(26);
          margin-bottom: vw(29);
          line-height: vw(39);
          -webkit-line-clamp: 4;
        }
        .moduleBox-list-timeBox, .moduleBox-list-textBox{
          margin-bottom: vw(17);
          img{
            width: vw(22);
            height: vw(22);
          }
          .moduleBox-list-name{
            font-size: vw(25);
            line-height: vw(34);
          }
          .moduleBox-list-text{
            font-size: vw(25);
            line-height:  vw(34);
          }
        }
        .moduleBox-list-textBox{
          img{
            width: vw(20);
            height: vw(23);
          }
          .moduleBox-list-text{
            line-height: vw(34);
          }
        }
      }
      .moduleBox-list-more{
        left: vw(20);
        bottom: vw(33);
        font-size: vw(24);
      }
    }
  }
}
</style>
